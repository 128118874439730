<template>
    <div class="partners">
        <div class="partners__container container" :style="backgroundColor">
            <h4 class="partners__title" :style="partnerTitleStyles">
                {{ blok.title }}
            </h4>
            <ul class="partners__list" :style="gap">
                <template v-for="partner in blok.partners" :key="partner.image.id">
                    <li class="partners__list-item">
                        <NuxtImg
                            v-if="partner.image?.filename"
                            :src="partner.image.filename"
                            :title="partner.image.title"
                            :alt="partner.image.alt"
                            provider="storyblok"
                            format="webp"
                            loading="lazy"
                            class="partners__list-image"
                            densities="x1"
                        />
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IPartners } from '~ui/types/components/Partners';

const props = defineProps<{ blok: IPartners }>();

const backgroundColor = computed(
    () => `${props.blok.BackgroundColor?.color ? `background-color: ${props.blok.BackgroundColor?.color};` : ''}`
);

const gap = computed(() => `${props.blok.ImagesGap ? `column-gap: ${props.blok.ImagesGap}rem;` : ''}`);

const partnerTitleStyles = computed(() => {
    return [
        `${props.blok.TitleBottomMargin ? `margin-bottom: ${props.blok.TitleBottomMargin}rem;` : ''}`,
        `${props.blok.TitleFontSize ? `font-size: ${props.blok.TitleFontSize}px;` : ''}`,
        `${props.blok.TitleFontWeight ? `font-weight: ${props.blok.TitleFontWeight};` : ''}`,
        `${props.blok.TitleColor?.color ? `color: ${props.blok.TitleColor?.color};` : ''}`,
    ];
});
</script>

<style lang="postcss" scoped>
.partners {
    @apply text-center;
    &__container {
        @apply gap-y-8 gap-x-4 flex-col p-4 flex justify-center items-center mx-auto;
        @apply md:gap-y-0 md:gap-x-2 md:flex-row;
        @apply xl:gap-x-12;
    }
    &__title {
        @apply text-center flex items-center list-none box-border font-bold leading-[1.2] text-primary text-xl mt-0 mb-2 pt-[9px] pb-0 px-0 min-w-max;
        @apply md:text-left;
    }
    &__list {
        @apply gap-y-8 flex-col gap-x-2 flex justify-center items-center;
        @apply md:gap-y-0 md:flex-row md:gap-x-4;
        @apply xl:gap-x-12;
        &-item {
            @apply py-2 box-content relative;
        }
        &-image {
            @apply w-full h-full;
        }
    }
}
</style>
